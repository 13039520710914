<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <SeasonalHiveStatus />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SeasonalHiveStatus from '@/components/SeasonalAnalysis/SeasonalHiveStatus.vue';

  export default {
    name: 'SeasonalAssessment',
    components: {
      SeasonalHiveStatus
    },
    data: () => ({
      inspection_on: "$vuetify.icons.inspection_on",
    })
  }
</script>
