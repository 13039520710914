<template>
  <v-card flat class="inspection-card" id="seasonal-hive-status-card">
    <v-row>
      <v-col cols="12">
        <div class="inspection-card-title">{{$t('hiveStatus')}}</div>
      </v-col>
    </v-row>

    <NoData v-if="hasNoData"></NoData>
    <v-row v-else>
      <v-col cols="12">
        <GChart
          type="LineChart"
          :data="seasonalGeneralStatus"
          :options="chartOptions"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <div class="mr-8">
          <v-icon color="#03cfbb" small>mdi-star</v-icon>
          <span class="legend-title">{{$t('excellent')}}</span>
        </div>
        <div class="mr-8">
          <v-icon color="#2AA8F8" small>mdi-square-medium</v-icon>
          <span class="legend-title">{{$t('good')}}</span>
        </div>
        <div>
          <v-icon color="#FD6150" small>mdi-circle-medium</v-icon>
          <span class="legend-title">{{$t('bad')}}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { GChart } from 'vue-google-charts'

  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'SeasonalHiveStatus',
    components: {
      GChart,
      NoData
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "selectedInspectionDateObj",
        "seasonalGeneralStatus"
      ]),
      hasNoData() {
        return this.seasonalGeneralStatus.length === 0;
      }
    },
    data: () => ({
      chartOptions: {
        legend: 'none',
        series: {
          0: {color: '#03cfbb', pointShape: 'star', pointSize: 18},
          1: {color: '#2aa8f8', pointShape: 'square', pointSize: 10},
          2: {color: '#fd6150', pointShape: 'circle', pointSize: 4}
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          format: '#\'%\''
        },
        hAxis: {
          format: 'd/M/yy'
        },
        height: 300,
        chartArea: {
          width: '100%',
          height: '90%',
          top: 10,
          bottom: 20,
          left: 35,
          right: 20
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      }
    })
  }
</script>

<style type="text/css">
  #my-app .seasonal-tooltip {
    text-align: center;
    background: #262338;
    border-radius: 12px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
  }
</style>
