<template>
  <v-container fluid id="seasonal-analysis">
    <v-row class="pa-2 pa-sm-6">
      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card flat class="my-card">
          <h2>{{$t('seasonalAnalysis')}}</h2>
          <p class="seasonal-paragraph">{{$t("seasonalAnalysis_text")}}</p>
          <v-row class="mt-7" align="center" justify="space-between">
            <v-col cols="12">
              <v-select
                :items="dateDurations"
                v-model="seasonalChosenDateDuration"
                label="Select Date Range"
                placeholder="Select Date Range"
                solo
                flat
                hide-details
                color="#262338"
                class="seasonal-select-box"
                append-icon="mdi-chevron-down"
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template slot="item" slot-scope="data">
                  <span class="my-list-item">{{$t(data.item.text)}}</span>
                </template>
                <template slot="selection" slot-scope="data">
                  <span class="my-list-item">{{$t(data.item.text)}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" v-if="chosenDateDuration == 0">
              <date-picker
                v-model="seasonalDateRange"
                :placeholder="$t('selectDate')"
                value-type="date"
                format="MMM D, YYYY"
                :clearable="false"
                class="datepicker-container"
                popup-class="my-datepicker-popup seasonal-datepicker-popup"
                input-class="datepicker-btn"
                range-separator=" - "
                :range="true"
                :lang="lang"
                :disabled-date="(date) => date >= new Date()"
              >
                <template v-slot:icon-calendar>
                  <v-img class="calendar-icon" src="@/assets/calendar.svg"></v-img>
                </template>
              </date-picker>
            </v-col>
          </v-row>
          <v-btn block outlined class="my-btn analyze-btn"
            @click="getSeasonalData">
            {{$t('analyzeData')}}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" class="pa-0">
        <SeasonalAssessment class="mb-6" />

        <SeasonalGrowth class="mb-6" />

        <SeasonalHarvest />
      </v-col>

      <v-overlay :value="showLoader">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';

  import SeasonalAssessment from '@/components/SeasonalAnalysis/SeasonalAssessment.vue';
  import SeasonalGrowth from '@/components/SeasonalAnalysis/SeasonalGrowth.vue';
  import SeasonalHarvest from '@/components/SeasonalAnalysis/SeasonalHarvest.vue';

  export default {
    name: 'SeasonalAnalysis',
    components: {
      DatePicker,
      SeasonalAssessment,
      SeasonalGrowth,
      SeasonalHarvest
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "seasonalFromDate",
        "seasonalToDate",
        "chosenDateDuration",
        "dateRange"
      ]),
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      seasonalDateRange: {
        get() {
          return this.$store.state.seasonal_analysis.dateRange;
        },
        set(range) {
          this.$store.commit('setDateRange', range);
        }
      },
      seasonalChosenDateDuration: {
        get() {
          return this.$store.state.seasonal_analysis.chosenDateDuration;
        },
        set(dateDuration) {
          this.$store.commit('setChosenDateDuration', dateDuration);
        }
      }
    },
    data: () => ({
      showLoader: false,
      assessment: true,
      growth: true,
      harvest: true,
      checkbox_off: "$vuetify.icons.checkbox_off",
      inspection_on: "$vuetify.icons.inspection_on",
      dateDurations: [
        {"value": 30, "text": "month1"},
        {"value": 90, "text": "month3"},
        {"value": 180, "text": "month6"},
        {"value": 365, "text": "year"},
        {"value": 0, "text": "custom"},
      ]
    }),
    mounted() {
      this.getSeasonalData();
      this.setIsHiddenApiaryButton(false);
    },
    methods: {
      ...mapActions([
        "getApiaryHives",
        "resetSeasonalCharts",
        "seasonalGroupMetrics",
        "setSeasonalFromToDates",
        "setDateRange",
        "setChosenDateDuration",
        "setIsHiddenApiaryButton"
      ]),
      getSeasonalData() {
        this.showLoader = true;

        let promises = [];
        let metrics = [
          'generalStatus',
          'populationFramesS',
          'broodFramesS',
          'harvestedFramesS'
        ];

        this.updateSeasonalDateRange().then(() => {
          this.resetSeasonalCharts().then(() => {
            for (let k = 0; k < metrics.length; k++) {
              let promise = this.getSeasonalMetric(metrics[k]);
              promises.push(promise);
            }

            Promise.all(promises).then((res) => {
              this.showLoader = false;
              this.$gtag.event(
                'Seasonal Analysis',
                {
                  'event_category': 'Analytics',
                  'event_label': this.chosenDateDuration
                }
              );
            });
          });
        });
      },
      getSeasonalMetric(field) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            date_from: this.seasonalFromDate,
            date_to: this.seasonalToDate,
            field: field
          };
          this.seasonalGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        });
      },
      updateSeasonalDateRange() {
        return new Promise((resolve, reject) => {
          let payload = {};
          if (this.chosenDateDuration != 0) {
            let fromDate = moment().subtract(this.chosenDateDuration, "days").format("YYYY-MM-DD");
            let toDate = moment().format("YYYY-MM-DD");

            payload = {
              fromDate: fromDate,
              toDate: toDate
            };
            
          } else {
            payload = {
              fromDate: moment(this.dateRange[0]).format("YYYY-MM-DD"),
              toDate: moment(this.dateRange[1]).format("YYYY-MM-DD")
            }
          }
          this.setSeasonalFromToDates(payload).then(() => {
            resolve();
          });
        });
      }
    }
  }
</script>

<style type="text/css">
  .datasets {
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.75px;
  }

  .seasonal-paragraph {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
  }

  #seasonal-analysis p.seasonal-paragraph {
    margin: 15px 0 30px;
  }

  #seasonal-analysis .legend-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-left: 6px;
  }

  .seasonal-datepicker-popup {
    width: 652px;
  }

  .seasonal-select-box {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    border-radius: 12px;
    background-color: #ffffff;
    border: 2px solid #EAAC30;
    padding: 16px 24px;
  }

  .hives-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.75px;
  }

  .hive-count {
    background-color: #eaac30;
    text-align: center;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    padding: 3px 8px;
    width: 33px;
    height: 28px;
  }

  .inspection-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  .inspection-label:hover {
    cursor: pointer;
  }

  #seasonal-analysis .my-checkbox {
    margin: 0 0 0 10px;
    padding: 0;
  }

  #seasonal-analysis .my-card button.analyze-btn {
    font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
    line-height: 32px;
    letter-spacing: 0.75px;
    padding: 20px 24px;
    margin-top: 80px;
    height: 65px;
  }

  #seasonal-analysis .my-card button.analyze-btn:hover {
    background-color: #262338;
    color: #ffffff;
  }
  
  button.analyze-btn:hover::before {
    opacity: 0;
  }
  
  #seasonal-analysis .inspection-card {
    padding: 25px 30px;
    border-radius: 8px;
  }

  .inspection-card-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #1A1A1A;
  }

  .inspection-card-subtitle {
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
    margin-right: 5px;
  }

  .inspection-card-subtitle-number {
    font-weight: 600;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
    margin-left: 5px;
  }

  .inspection-dataset-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #262338;
    margin-left: 10px;
  }

  #seasonal-analysis .legend-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-left: 6px;
  }

  #seasonal-analysis .legend-number {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-left: 6px;
  }

  @media screen and (max-width: 960px) {
    #seasonal-analysis .my-card button.analyze-btn {
      margin-top: 50px;
    }

    .seasonal-datepicker-popup {
      width: 80vw;
      top: 100px !important;
    }
  }
</style>
