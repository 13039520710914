<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PopulationFrames />
      </v-col>
      <v-col cols="12">
        <BroodFrames />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import PopulationFrames from '@/components/SeasonalAnalysis/PopulationFrames.vue';
  import BroodFrames from '@/components/SeasonalAnalysis/BroodFrames.vue';

  export default {
    name: 'SeasonalGrowth',
    components: {
      PopulationFrames,
      BroodFrames
    },
    data: () => ({
      inspection_on: "$vuetify.icons.inspection_on",
    })
  }
</script>

