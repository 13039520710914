<template>
  <v-card flat class="inspection-card" id="seasonal-hive-status-card">
    <v-row>
      <v-col cols="12">
        <div class="inspection-card-title">{{$t('populationFrames')}}</div>
      </v-col>
    </v-row>

    <NoData v-if="hasNoData"></NoData>
    <v-row v-else>
      <v-col cols="12">
        <GChart
          type="LineChart"
          :data="seasonalPopulationFrames"
          :options="lineChartOptions"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-center pt-0">
        <div class="mr-8">
          <v-icon color="#2AA8F8" small>mdi-circle-medium</v-icon>
          <span class="legend-title">{{$t('populationFrames')}}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { GChart } from 'vue-google-charts'

  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'PopulationFrames',
    components: {
      GChart,
      NoData
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "selectedInspectionDateObj",
        "seasonalPopulationFrames",
        "seasonalPopulationFramesHives"
      ]),
      hasNoData() {
        return this.seasonalPopulationFrames.length === 0;
      }
    },
    data: () => ({
      lineChartOptions: {
        legend: 'none',
        series: {
          0: {color: '#2aa8f8', pointShape: 'circle', pointSize: 5}
        },
        hAxis: {
          format: 'd/M/yy'
        },
        height: 300,
        chartArea: {
          width: '100%',
          height: '90%',
          top: 10,
          bottom: 20,
          left: 35,
          right: 20
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      },
      columnChartOptions: {
        isStacked: true,
        legend: 'none',
        series: {
          0: {color: '#03cfbb'},
          1: {color: '#fd6150'}
        },
        hAxis: {
          format: 'd/M/yy'
        },
        height: 300,
        chartArea: {
          width: '100%',
          height: '90%',
          top: 10,
          bottom: 20,
          left: 35,
          right: 20
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      }
    })
  }
</script>

<style type="text/css">

</style>
